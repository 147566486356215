<template>
  <!--首页-->
  <div class="div_center service-main">
    <div class="center" style="margin-bottom: 45px; padding-top: 40px">
      <div class="service-rec">
        <Row>
          <Col style="display: flex;flex-flow: column;justify-content: space-around;width: 224px">
            <TabFloorItem
                v-for="(item, index) in floorTopMap"
                :key="item.id"
                :config="{index, itemNum: floorTopMap.length, tabIndex}"
                :data="item"
                @chooseItem="onTabItemChange"
                @tabIndexChange="onTabIndexChange"
            />
          </Col>
          <Col style="width: 956px;margin: 20px 0">
            <div class="dp-flex flex-wrap service-rec-con">
              <SGoodItemTop v-for="item in tabItem" :key="item.id" :data="item" :type="'service'" :detailUrlPath="'serviceDetail'"/>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div class="div_center">
      <div v-for="item in floorMainMap" :key="item.id" class="center" style="margin-bottom: 40px;">
        <div class="dpyx">
          <div style="margin: 0 40px;width:300px;" class="dp-flex flex-between flex-align-center">
            <div class="h-slash"></div>
              {{ item.name }}
            <div class="h-slash"></div>
          </div>
          <div style="color:#999999;font-size:18px">官方精选热门推荐{{ item.floorType | floorTxtFT }}</div>
        </div>
        <div class="rmzl_content_real">
          <SGoodItem v-for="(gItem,index) in item.objs" :key="item.floorType + index" :data="gItem" :gType="item.floorType"
                     :detailUrlPath="getDetailPath(item.floorType)"
                     :serviceNum="serviceNumMap(gItem.id)" />
          <div style="position: absolute;bottom: 25px;left: 50%;transform: translateX(-50%)" v-if="item.floorType !== 'THIRD_PARTY_FLOOR'">
            <router-link :to="{path: getMorePath(item.floorType)}" class="more-url" target="_blank">查看更多
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- <img style="width:100%;min-width:1200px" src="~@/assets/image/service-footer-bg.png" /> -->
    <div style="height: 1px;"></div>
  </div>
</template>

<script>
import {getScienceServiceIndex, getServiceNum} from "@/plugins/api/scienceServiceApi";
import SGoodItem from "../psGoodItem";
import SGoodItemTop from "../psGoodItemTop";
import TabFloorItem from "../psTabFloorItem";

export default {
  name: "ScienceServiceIndex",
  data() {
    return {
      floorTop: [],
      floorMain: [],
      icons: ["iconservice", "iconxueshimaoxuexibiye", "iconshangbiao", "iconextra"],
      tabItem: [],
      tabIndex: 0,
      serviceNum: [],
    };
  },
  components: {
    SGoodItem,
    TabFloorItem,
    SGoodItemTop
  },
  mounted() {
    this.initPage();
  },
  computed: {
    floorTopMap() {
      return this.floorTop.map((item, index) => {
        let data = Object.assign({}, item.config);

        return Object.assign(
            {icon: this.icons[index], showLabel: index !== 0, showSubTitle: index === 0}, data
        );
      });
    },
    floorMainMap() {
      let result = [];
      this.floorMain.forEach((item, index) => {
        let data = item.config;
        if (data && data.labels) {
          data.labels.forEach(label => {
            result.push(Object.assign({floorType: data.floorType}, label));
          })
        }
      });


      return result;
    }
  },
  methods: {
    getMorePath(type) {
      if (type === 'SERVICE_GOODS_FLOOR2') {
        return '/home/serviceList';
      } else if(type =='SHOP_FLOOR'){
        return '/home/storeList?tab=2';
      } else{
        return '/home/storeList?tab=1'
      }
    },
    getDetailPath(type) {
      if (type !== 'THIRD_PARTY_FLOOR') {
        return 'serviceDetail?goodId=';
      } else {
        return 'OutsideStore?shopId=';
      }
    },
    initPage() {
      getScienceServiceIndex().then((res) => {
        if (res.code === 0) {
          this.floorTop = res.result.floors.slice(0, 4);
          this.floorMain = res.result.floors.slice(4);

          this.tabItem = this.floorTop[0]?.config?.labels[0]?.objs;
          let shopFloor = {};
          res.result.floors.forEach((item,index) => {
            if(item.config.floorType == 'THIRD_PARTY_FLOOR') {
              shopFloor = item;
            }
          })
          let id = "";
          shopFloor.config.labels[0].objs.forEach((item,index) => {
            id += item.id + ','
          })
          id = id.slice(0,id.length-1)
          getServiceNum({shopIds: id}).then(res => {
            if (res.code == 0) {
              this.serviceNum = res.result
            }

          })
        }
      });
      //getPatentIndex()
    },
    serviceNumMap(id) {
      let count = 0 + ''
      this.serviceNum.map((item, index) => {
        if (item.shopId == id) {
          count = item.serviceCount + ''
        }
      })
      return count
    },
    onTabItemChange(item) {
      this.tabItem = item;
    },
    onTabIndexChange(index) {
      this.tabIndex = index;
    },
    goListPage() {
      this.$router.push({
        path: "serviceList",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.pay_div {
  display: flex;
  height: 60px;
  width: 180px;
  border: 1px solid rgb(205, 240, 243);
  margin-left: 20px;
  font-size: 20px;
  align-items: center;
  cursor: pointer;
}
// .rmzl_content_real > div:not(.good-con) {
//   margin-bottom: 0!important;
// }
// .rmzl_content_real > div.good-con:hover {
//   box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
// }
// .rmzl_content_real > div:not(.good-con):hover{
//   box-shadow: none!important;
// }
.service-main{
  background: url('~@/assets/image/patent-service-bg.png');
  background-size: 100% auto;
}

.service-rec {
  width: 100%;
  background: url('~@/assets/image/patent/patent-header-bg.png');
  background-size: 100% 100%;
  border-radius: 4px;
  .service-rec-con {
    background:#fff;
    height: 390px;
    border-radius: 4px;
  }
}

.service_list > div:hover {
  box-shadow: 0 6px 20px -6px rgba(0, 0, 0, 0.1);
}

#order > span {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.service_div {
  font-size: 14px;
  line-height: 50px;

  & span {
    display: inline-block;
    height: 50px;
    margin-right: 40px;
    cursor: pointer;
  }
}

.more-url{
  font-size:14px;
  color:#1890FF;
  text-decoration: underline;
  &:hover{
    text-decoration: underline;
  }
}
.rmzl_content_real {
  position: relative;
  padding: 20px 20px 74px 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
}

.dpyx {
  height: 135px;
  font-size: 32px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.h-slash{
  height: 2px;
  width: 60px;
  background: #cccccc;
}

.select_span {
  color: rgb(252, 117, 0);
  border-bottom: 2px solid rgb(252, 117, 0);
}

.left_desc {
  padding-left: 20px;
  padding-top: 5px;
}

.div_center {
  width: 100%;
}

.center {
  width: 1200px;
  margin: 0 auto;
}
</style>
